@import "styles/utils";

.iconsWrapper {
  display: inline-flex;

  @include phone {
    display: none;
  }

  .search,
  .burger {
    margin-left: 18px;
    width: 24px;
    height: 24px;

    @include tablet {
      width: 15px;
      height: 15px;
    }
  }

  .search {
    margin-left: 0;
  }

  .iconActive {
    opacity: 1;
  }
}

.searchInput {
  @include tabletDown() {
    display: none;
  }
}

.searchIconContainer {
  opacity: 1;
  transition: all 0.2s ease;
  transition-delay: 0.2s;

  &.searchIconHidden {
    opacity: 0;
  }
}

.profileWrapper {
  @include prevent-tab-highlights;
  position: relative;
  padding-left: 18px;

  @include tablet {
    margin-right: 10px;
    padding-left: 15px;
  }
}

.profileHeader {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%;
  text-align: center;

  @include tablet {
    width: 18px;
    height: 18px;
  }
}

.avatar {
  height: 100%;
  width: 100%;
}

.username {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--primary-text-color);
  text-transform: uppercase;
  font-size: 16px;
  opacity: 0.85;
  z-index: 1005;

  @include tablet {
    font-size: 9px;
  }
}

.modal {
  position: absolute;
  padding: 30px 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: -40px;
  right: calc(22px - var(--app-padding-right));
  background-color: var(--bg-color);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 1009;
  max-width: 180px;

  @include desktopDown {
    padding: 15px 45px;
  }

  @include tabletDown {
    top: -45px;
    right: calc(52px - var(--app-padding-right));
  }

  &:hover {
    text-transform: none;
  }
}

.modalItem {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  cursor: pointer;

  svg,
  img {
    width: 28px;
    height: 28px;
  }

  @include tabletDown {
    height: 46px;
  }
}

.modalTitle {
  width: fit-content;
  min-width: 150px;
  font-size: 16px;
  font-weight: lighter;
  opacity: 0.87;
  text-align: left;
  letter-spacing: 0.4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width:150px;
  padding-right:18px;

  @include desktopDown {
    min-width: 140px;
  }

  @include tabletDown {
    font-size: 14px;
  }

  &Active {
    font-weight: bold;
  }
}

.modalUserIcon {
  @extend .profileHeader;
}

.modalAvatar {
  border-radius: 50%;
}

.modalUserIcon,
.modalAvatar {
  width: 30px;
  height: 30px;

  @include tabletDown {
    width: 28px;
    height: 28px;
  }
}

.modalUsername {
  @extend .username;
  font-size: 16px;

  @include tabletDown {
    font-size: 14px;
  }
}
