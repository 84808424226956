@import "styles/utils";
@import "styles/variables";

.Container {
  color: var(--primary-text-color);
  font-size: 14px;
  font-size: 16px;
  font-weight: lighter;

  @include tablet {
    font-size: 14px;
  }

  @include phone {
    margin-bottom: 250px;
    font-size: 16px;
  }

  .Description {
    margin-top: -7px;
  }

  .ProfileList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
