@import "src/styles/utils";
@import "src/styles/variables";

.downloadInfoContainer {
  .mobile,
  .desktop {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .nodata{
    display: none;
  }

  .mobile {
    @include tabletDown() {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .button {
    margin: 0;
    padding: 0 60px 0 60px;
    width: max-content;
    height: 70px;
    font-size: 1.8rem;
    border-radius: 100px 90px 90px 90px;
    font-weight: bold;

    @include desktopDown() {
      padding: 0 50px 0 50px;
      height: 55px;
      font-size: 2rem;
    }

    @include tabletDown() {
      padding: 0 34px 0 34px;
      height: 48px;
      font-size: 1.9rem;
    }

    @include phone() {
      font-size: 2.5rem;
    }
  }

  p {
    margin: 10px 0 35px 0;
    font-size: 1.5rem;
    max-width: 280px;
    overflow-wrap: break-word;

    @include tabletDown() {
      font-size: 1.3rem;
      font-weight: bold;
    }

    @include phone() {
      font-size: 1.75rem;
      max-width: 240px;
    }
  }
}
