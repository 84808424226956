@import "styles/utils";

$checkbox-size: 17px;

.profileWrapper {
  @include prevent-tab-highlights;
  position: relative;

  .profileHeader {
    display: inline-block;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    text-align: center;
    width: 3.5rem;
    height: 3.5rem;
    cursor: pointer;

    .avatar {
      height: 100%;
      width: 100%;
    }
    .username {
      z-index: 1005;
      opacity: 0.85;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--primary-text-color);
      text-transform: uppercase;
      font-size: 2.2rem;
      line-height: 100%;
    }
  }

  .modal {
    z-index: 1015;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--bg-color);

    @include hover {
      &:hover {
        text-transform: none;
      }
    }
    
    .modalItem {
      cursor: pointer;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 16px 12px;
      svg,
      img {
        width: 7rem;
        height: 7rem;
      }
    }
    .modalUsers {
      display: flex;
      justify-content: center;
      align-items: center;
      @include flex-flow(row wrap);
      width: 100%;
      .modalItem {
        padding: 0 12px;
      }
    }
    .modalAddProfile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 8px;
    }
    .modalTitle {
      min-width: 70px;
      opacity: 0.87;
      font-size: 1.5rem;
      text-align: center;
      white-space: pre;
      letter-spacing: 0.4px;
      font-weight: 600;
      margin-top: 8px;
    }

    .modalUserIcon {
      @extend .profileHeader;
      width: 7rem;
      height: 7rem;
      .modalAvatar {
        width: 100%;
        height: 100%;
      }
      .modalUsername {
        @extend .username;
        font-size: 4rem;
        font-weight: 600;
      }
    }

    .checkboxContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -$checkbox-size;
      margin-top: 40px;
      :global {
        .rc-checkbox-inner {
          width: $checkbox-size;
          height: $checkbox-size;
          margin-left: 0;
        }
      }
      span {
        margin-left: 12px;
        font-size: 1.2rem;
      }
    }
  }
}
