@import "styles/utils";

.menu {
  position: relative;
  min-height: 45px;
  min-width: 300px;
  padding-left: 125px;
  flex: 1;
  @include desktop {
    padding-left: 95px;
  }
  @include tablet {
    padding-left: 40px;
  }

  @include phone {
    margin-top: 10px;
    padding: 0 0 0 50px;
  }
  :global {
    .Menu {
      flex: 1;
      text-transform: uppercase;
      @include phone {
        margin-top: -15px;
        text-align: center;
      }
    }
    .Menu-item {
      margin: 0 65px 0 0 !important;

      @include tablet {
        margin: 0 25px 0 0 !important;
      }

      @include phone {
        margin: 0 10px 0 0 !important;
      }
    }
  }
}
