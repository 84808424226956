@import "styles/utils";

.container {
  @include phone(){
    position: fixed;
    top: 0;
    z-index: 1000;
    height: 200px;
    width: 100%;
  }
    display:flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 10px;
  .signInButton {
    font-size: 1.25rem;
    font-weight: 900;
    box-shadow: none;
    padding: 10px 2px;
    width: 9.5rem;
    height: 3.2rem;
    margin-right: -15px;
    border: 1px solid;

    @include hover {
      &:hover {
        box-shadow: 1px 7px 9px rgba(0, 0, 0, 0.15);
      }
    }

    @include phone {
      margin-right: 0;
      margin-bottom: 5px;
    }
  }
  .searchButton{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
  }
}
