@import "styles/utils";

$header-top-height: var(--header-height-mobile);

.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @include phone(){
    min-height: 150vh;
    margin-top: $header-top-height;
    margin-bottom: $header-top-height;
  }


  section {
    flex: 1;
    min-height: 100vh;
  }
}