.dotsLoader {
  path {
    fill: var(--app-dot-color);
  }
}

$animationTime: 1.4s;
$initialOpacity: 0.2;

@keyframes dot_animation{
  0%{opacity:$initialOpacity}
  20%{opacity:0.8}
  100%{opacity:$initialOpacity}
}

.first_dot{
  opacity: $initialOpacity;
  animation:dot_animation $animationTime ease-out infinite;
  animation-delay:0s;
  fill: var(--app-dot-color);
  path {
    fill: var(--app-dot-color);
  }
}
.second_dot{
  opacity: $initialOpacity;
  animation-delay:$animationTime * 0.5;
 }
.third_dot{
  opacity: $initialOpacity;
  animation-delay:$animationTime * 0.75
}
