@import "src/styles/utils";
@import "src/styles/variables";

.MediaDetailsBottomContent {
  margin-top: 64px;
  padding-left: calc(var(--app-padding-left-large) * 2 - 28px);
  padding-right: var(--app-padding-right);
  font-size: 1.38rem;

  @include desktop() {
    padding-left: calc(var(--app-padding-left-desktop-tablet) * 2 + 8px);
    margin-top: 200px;
  }

  @include tabletDown() {
    padding-left: calc(var(--app-padding-left-large) + 10px);
    margin-top: 82px;
    margin-bottom: 100px;
    font-size: 1.3rem;
  }

  @include phone() {
    margin-top: 80px;
    padding-left: calc(var(--app-padding-left-large) / 2 - 22px);
    padding-right: calc(var(--app-padding-right) / 2);
    font-size: 2rem;
  }

  .descriptionText {
    max-width: 56%;
    font-size: inherit;
    line-height: 30px;
    font-weight: lighter;

    @include desktopDown() {
      max-width: 64%;
    }

    @include tabletDown() {
      max-width: 80%;
      line-height: 19px;
    }

    @include phone() {
      max-width: 100%;
    }
  }

  .article{
    font-weight: lighter;
    font-size: 1.1rem;
    opacity: 0.8;

    @include phone() {
        font-size: 1.6rem;
    }
    @include phone-landscape() {
        padding-bottom: 10rem;
    }

    @include tabletDown() {
      margin-top:-100px;
    }
  }
}
