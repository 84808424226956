@import "styles/utils";
@import "styles/variables";

.container {
  margin-top: 60px;

  @include tabletDown() {
    display: none;
  }

  header {
    padding-left: calc(var(--app-padding-left-large) * 2 - 20px);
    font-weight: normal;

    @include desktop {
      padding-left: calc(var(--app-padding-left-desktop-tablet) * 2 + 8px);
    }

    p {
      font-size: 1.32rem;
    }
  }

  & > div:first-of-type {
    padding-top: 0;
  }

  [class$="slick-list"] {
    padding-left: calc(var(--app-padding-left-large) - 20px);
  }
}
